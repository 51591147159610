import '../../styles/homeandlifepage.css';

import { Link } from 'gatsby';
import React, { useEffect, useState } from 'react';

import Layout from '../../components/layout';
import SEO from '../../components/seo';
import bitmap_2 from '../../images/bitmap_2.png';
import Illustration from '../../images/illustration-bubble-tip.png';
import bitmap_1 from '../../images/images/bitmap-new.jpeg';
import girlwithcat from '../../images/images/group-3.png';
import PH_Image from '../../images/images/group-8.png';
import righttick from '../../images/images/icon-tick-green.svg';
import safecologo from '../../images/safecohome.png';

const SafecoHomePage = ( { data, parent } ) => {

  return (
    <Layout header={'menu'}>
      <SEO
        title="Homeowners insurance by Safeco, Safeco, Best Life Insurance Policy, Life insurance quotes online, Online insurance"
        canonicalUrl='https://www.getmybubble.com/safecohome/'
        description="Homeowners insurance by Safeco, Safeco, Homeowners insurance quotes online, Online  homeowners insurance policy"
        keywords="Homeowners insurance by Safeco, Safeco, Homeowners insurance quotes online, Online  homeowners insurance policy"
      />

      {/* <Menu /> */}

      <div className="flexColumn carrierCommonPage">
        <div className="container-fluid carrier-plan-container_1">
          {/* carrier-plan-container */}

          <div className="container">
            <div className="row">
              <div className="col-lg-6 ">
                <div className="padding-left">
                  <img src={safecologo} alt="logo" width="200"/>

                  <p className="carrier-plan-section1-para">
                    With Safeco's broad array of coverage options, you can independently tailor your policy to fit your needs, and your budget.
                  </p>

                  <div className="flexRow align-btns ml-1">
                    <Link to="/">
                      <button type="button" className="orangeBtn mr-3">
                      Get a quote now
                      </button>
                    </Link>

                    <a href="#start-exploring" style={{ color: '#ff7007', textDecoration: 'none' }}>
                      <button type="button" className="explore">
                        Explore
                      </button>
                    </a>
                  </div>
                </div>
              </div>

              <div className="col-lg-6 sbli-image" style={{ textAlign: 'center' }}>
                <img src="https://devuiassetscdn.getmybubble.io/images/group-7-house.png" className="carrier-right-img-mv mt-4 mt-sm-4 mt-md-4 mt-lg-0" alt="" />
              </div>
            </div>
          </div>

        </div>

        {/*section 2*/}

        <div id="start-exploring" className="container-fluid" style={{ marginTop: '80px' }}>
          <div className="row">
            <div className="col-lg-3"></div>

            <div className="col-lg-6 costom-padding19">
              <h3 className="stillwater-title1">
                Why to choose Safeco Homeowners Insurance?
              </h3>

              <p className="stillwater-para1">
                With Safeco's broad array of coverage options, you can independently tailor your policy to fit your needs, and your budget.
              </p>

            </div>

            <div className="col-lg-3"></div>
          </div>
        </div>

        {/* Product Highlights Section */}

        <div className="container-fluid">
          <div className="row" style={{ marginTop: '30px' }}>
            <div className="col-lg-6">
              <div className="row">
                <div className="col-lg-2"></div>

                <div className="col-lg-9 product-container-alignment costom-padding19">
                  <div className="stillwater-product-container">

                    <div className="align-horizontal-center">
                      <img src={PH_Image} className="ph-desktop-view" alt="phdesk"/>

                      <h3
                        className="ph-title ph-desktop-view">
                        Safeco Homeowner Discounts
                      </h3>

                      <h3
                        className="ph-title ph-mobile-view">
                        Safeco Homeowner Discounts
                      </h3>

                      <img src={PH_Image} className="ph-mobile-view" alt="phmob"/>
                    </div>

                    <div
                      style={{ display: 'flex', alignItems: 'center', marginBottom: '1.5rem' }}>
                      <img src={righttick} className="img-responsive" alt="tick" style={{ verticalAlign: 'middle' }}></img>

                      <div className="ph-item-style pl-3">
                        Safeco Package
                        Combine your auto policy and Safeco Homeowners Policy into a Safeco Package and save
                      </div>
                    </div>

                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: '1.5rem' }}>
                      <img src={righttick} alt="tick"/>

                      <div className="ph-item-style pl-3">
                        Umbrella Discount
                        2 percent discount with in-force umbrella policy
                      </div>

                    </div>

                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: '1.5rem' }}>
                      <img src={righttick} alt="tick"/>

                      <div className="ph-item-style pl-3">
                        Burglar Alarm Discount
                        2 percent with active alarm system
                      </div></div>

                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: '1.5rem' }}>
                      <img src={righttick} alt="tick"/>

                      <div className="ph-item-style pl-3">
                        Hail Resistive Roofing Discount
                        Upto 15 percent
                      </div></div>

                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: '1.5rem' }}>
                      <img src={righttick} alt="tick"/>

                      <div className="ph-item-style pl-3">
                        Newer Home Discount
                        Applies to dwellings that are &#60; 10 years old
                        20 percent discount for a new home
                      </div></div>

                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: '1.5rem' }}>
                      <img src={righttick} alt="tick"/>

                      <div className="ph-item-style pl-3">
                        Renewal Discount
                      </div></div>

                  </div>

                </div>

                <div className="col-lg-1"></div>
              </div>
            </div>

            <div className="col-lg-6 carrier-middle-container carrier-middle-container-mv">
              <h4 className="stillwater-col2-title">How your home deductible works?</h4>

              <p className="stillwater-col2-para">
                A home insurance deductible is what you pay out of pocket to repair your home if you have a claim. For example, if you have a $500 deductible and $2,000 in repair costs, you'll pay $500 and Safeco will pay the remaining $1,500.
              </p>

              <p className="stillwater-col2-para">
                You have a choice of deductible amounts; choosing a higher deductible lowers your insurance rate but requires greater out-of-pocket expenses should you have an accident.
              </p>

              <h4 className="stillwater-col2-title">Standard Coverages:</h4>

              <ul className="stillwater-list stillwater-list-item stillwater-list-mv common-width">
                <li>
                  <b>Dwelling Coverage</b>

                  <p>Your home will be protected against losses from events such as fire or lightning; weight of ice, snow, or sleet; windstorms; hail; theft or vandalism; and more.</p>
                </li>

                <li>
                  <b>Personal Property Coverage</b>

                  <p>If your personal possessions—including furniture, clothing, and appliances—are damaged or stolen as a result of a covered loss, Safeco will arrange for them to be repaired or replaced.</p>
                </li>

                <li>
                  <b>Personal Liability Coverage</b>

                  <p>Safeco will defend you if someone makes a claim against you for accidentally causing an injury or damaging property — for example, if someone is hurt in your yard or you damage property at someone else's home.</p>
                </li>

              </ul>

              <h4 className="stillwater-col2-title">Optional Coverages:</h4>

              <ul className="stillwater-list stillwater-list-item stillwater-list-mv common-width">
                <li>
                  <b>Valuable Articles Coverage</b>

                  <p>Extra coverage for high-value items such as jewelry, art, collectibles, or camera equipment.</p>
                </li>

                <li>
                  <b>Equipment Breakdown Coverage</b>

                  <p>For just $2 a month, equipment breakdown coverage is a cost-effective way to keep critical household equipment up and running after mechanical or electrical breakdowns—which are often not covered under a standard homeowners policy.</p>
                </li>

                <li>
                  <b>Guaranteed Repair Network</b>

                  <p>This program connects you with prequalified experts to create estimates and perform repairs quickly, seamlessly, and with a guaranteed material and labor warranty backed by Safeco.</p>
                </li>

                <li>
                  <b>Earthquake coverage</b>

                  <p>Earthquake coverage is available in select states as either an endorsement to the home contract or a separate policy.</p>
                </li>

                <li>
                  <b>Service Line</b>

                  <p>Considering adding Service Line coverage which is a cost-effective way to insure against service line failures - which are often not covered under a standard Homeowner policy.</p>
                </li>

                <li>
                  <b>Extended Dwelling Coverage</b>

                  <p>In the event of a catastrophe, soaring demand for building materials and labor could cause reconstruction costs to increase, leaving policy limits inadequate. That's where extended dwelling coverage kicks in.</p>
                </li>

                <li>
                  <b>Identity Recovery Coverage</b>

                  <p>Recovering from identity theft can be a very costly, time-consuming, and stressful experience. With identity recovery coverage, you can replace that worry with calm for just $1 a month.</p>
                </li>

              </ul>


            </div>
          </div>
        </div>

        <div className="container stillwater-card-container">
          <div className="row">
            <div className="col-lg-3">
            </div>

            <div className="col-lg-6">
              <h3 className="stillwater-title1">When it comes to protecting your assets, we make the grade.</h3>

              <div className="card-deck align-stillwater-cards">

                <div className="row">
                  <div className="col-md-12 col-lg-12 section30 costom-padding19">
                    <div className="card stillwater-cards">
                      <div className="card-header align-stillwater-card-header">
                        <img src={bitmap_1} alt="bitmap"/>
                      </div>

                      <div className="card-body bg-card-body">
                        <p className="stillwater-card-text">Has the financial strength and capability to guarantee our customers' polices and keep them secure.</p>
                      </div>
                    </div>
                  </div>

                  {/* <div className="col-md-6 col-lg-6 section30 costom-padding19">
                    <div className="card stillwater-cards">
                      <div className="card-header align-stillwater-card-header">
                        <img src={bitmap_2} alt="bitmap"/>
                      </div>

                      <div className="card-body bg-card-body">
                        <p className="stillwater-card-text">Proven track record of predicting financial stability in the insurance industry and substantiates</p>
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-3">
          </div>
        </div>

        <div className="plansForYou">
          <div className="orangeBackGround">
            <div>
              Find out which plan is right for you
            </div>

            <div className="customizedPlan">
              Policies are customized according to your needs.
              We have simplified the process to help you find the right policy that fits your budget and loved ones.
            </div>

            <Link to="/" type="button" className="btnWhite">
              Get your quote now
            </Link>
          </div>
        </div>
      </div>
    </Layout> );
};

export default SafecoHomePage;
